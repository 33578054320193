<template>
  <div>
    <form id="frm">
      <h2 class="content_title">화물정보</h2>
      <table class="tbl_row">
        <colgroup>
          <col width="130"><col><col width="130"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>실화주</th>
            <td>
              <input type="text" id="realCompany" name="" class="col_6" @keyup="checkValidationRealCompany()">
              <span class="float_right">
                <input type="checkbox" id="c1" name="cc" />
                <label for="c1"><span></span>콘솔부킹</label>
              </span>
            </td>
            <th>G. Weight</th>
            <td>
              <span class="wg">
                <input type="text" id="inp_weight_ton" name="" class="wid80" @keyup="checkValidationWeight()"> ton
              </span>
              <span style=" display:inline-block; width:30px; text-align:center" class="color_gray">/</span>
              <span class="wg">
                <input type="text" id="inp_weight_kg" name="" value="" class="wid80" @keyup="checkValidationWeight()"> kg
              </span>
            </td>
          </tr>
          <tr>
            <th scope="row">Ocean Freight</th>
            <td>
              <select class="col_6" id="oceanFreight" @change="checkValidationOceanFreight()">
                <option value="">선택</option>
                <option value="TEMP_A">PREP AID</option>
                <option value="TEMP_B">COLLECT</option>
              </select>
            </td>
            <th scope="row">SC No.</th>
            <td><input type="text" id="" name="tempName" @keyup="checkValidationScNo()"></td>
          </tr>
          <tr>
            <th>Bidding No.<span class="txt">(선택)</span></th>
            <td>
              <input type="text" id="" name="tempName">
            </td>
            <th>
              checkbox 선택
            </th>
            <td>
              <span class="position_relative">
                <input type="checkbox" id="sampleCheckbox_1" name="sampleCheckbox" value="T001" @click="checkValidationCheckbox()"><label for="sampleCheckbox_1">T0001</label>
                <input type="checkbox" id="sampleCheckbox_2" name="sampleCheckbox" value="T002" @click="checkValidationCheckbox()"><label for="sampleCheckbox_2">T0002</label>
                <input type="checkbox" id="sampleCheckbox_3" name="sampleCheckbox" value="T003" @click="checkValidationCheckbox()"><label for="sampleCheckbox_3">T0003</label>
                <input type="checkbox" id="sampleCheckbox_4" name="sampleCheckbox" value="T004" @click="checkValidationCheckbox()"><label for="sampleCheckbox_4">T0004</label>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="tbl_col mt20">
        <colgroup>
          <col width="25%" />
          <col width="15%" />
          <col width="10%" />
          <col width="10%" />
          <col width="35%" />
          <col width="5%" />
        </colgroup>
        <thead>
          <tr>
            <th>Col1</th>
            <th>Col1</th>
            <th>Col1</th>
            <th>Col1</th>
            <th>Col1</th>
            <th>
              <button class="tbl_icon plus" type="button" @click.prevent="addRows()">plus</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(vo, idx) in list" :key="'tr_' + idx">
            <td>
              <input type="text" name="arrCol1" v-model="vo.col1" @keyup="checkValidationTempList('arrCol1', idx)">
            </td>
            <td>
              <select name="arrCol2" v-model="vo.col2" @change="checkValidationTempList('arrCol2', idx)">
                <option value="">선택</option>
                <option value="sel1">SEL01</option>
                <option value="sel2">SEL02</option>
                <option value="sel3">SEL03</option>
              </select>
            </td>
            <td><input type="text" name="arrCol3" v-model="vo.col3" @keyup="checkValidationTempList('arrCol3', idx)"></td>
            <td><input type="text" name="arrCol4" v-model="vo.col4" @keyup="checkValidationTempList('arrCol4', idx)"></td>
            <td><input type="text" name="arrCol5" v-model="vo.col5" @keyup="checkValidationTempList('arrCol5', idx)"></td>
            <td>
              <button class="tbl_icon minus" type="button" @click.prevent="removeRows(idx)">minus</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt10 text_right">
        <a class="button blue lg mr5" href="#" @click.prevent="checkValidationAll()">확인</a>
        <a class="button gray lg" href="#" @click.prevent="clearValidationAll()">Tooltip clear all</a>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'SampleFormError',
  components: {
  },
  data () {
    return {
      list: [
        {
          col1: 'col1',
          col2: 'sel1',
          col3: '',
          col4: '',
          col5: ''
        },
        {
          col1: '',
          col2: 'sel2',
          col3: 'col3',
          col4: '',
          col5: ''
        },
        {
          col1: 'col1',
          col2: '',
          col3: 'col3',
          col4: '',
          col5: ''
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    // tooltip 전체 제거
    clearValidationAll () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltipAll(frm)
    },
    checkValidationAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

      if (!this.checkValidationRealCompany(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationWeight(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationOceanFreight(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationScNo(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationCheckbox(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationTempListAll(frm)) {
        isAllOk = false
      }

      return isAllOk
    },
    // 실화주 입력여부 체크
    checkValidationRealCompany (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#realCompany')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val === '') {
        msg = this.$t('js.bookingValidation.004') // '실화주명을 입력해 주세요'
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // G.Weight 값 체크
    checkValidationWeight (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elemTon = frm.querySelector('#inp_weight_ton')
      const elemKg = frm.querySelector('#inp_weight_kg')
      const valTon = elemTon.value
      const valKg = elemKg.value
      const maxTon = 1000
      const maxKg = 5000
      let isOk = true

      // 일단 Error tooltip 숨기고 아래 상황에 맞게 노출
      this.$ekmtcCommon.hideErrorTooltip(elemTon)
      this.$ekmtcCommon.hideErrorTooltip(elemKg)

      if (valTon === '' && valKg === '') {
        this.$ekmtcCommon.showErrorTooltip(elemTon, '중량을 ton 혹은 kg 으로 입력해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
        isOk = false
      } else if (valTon !== '' && valKg !== '') {
        this.$ekmtcCommon.showErrorTooltip(elemTon, 'ton 혹은 kg 하나만 입력해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
        isOk = false
      } else if (valTon !== '') {
        // 숫자형식 체크 #01 - 숫자 입력 여부만 체크할 경우
        // this.$ekmtcCommon.isOnlyNumber
        if (!this.$ekmtcCommon.isOnlyNumber(valTon, { isComma: true })) {
          this.$ekmtcCommon.showErrorTooltip(elemTon, '숫자만 입력 가능합니다.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        }

        const numTon = this.$ekmtcCommon.getOnlyNumber(valTon, { isComma: true })

        if (numTon.num > maxTon) {
          this.$ekmtcCommon.showErrorTooltip(elemTon, '중량이 초과되었습니다.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        }
      } else if (valKg !== '') {
        // 숫자형식 체크 #02 - 숫자 입력여부 및 추가로 값 체크 해야 할경우
        // this.$ekmtcCommon.getOnlyNumber
        const numKg = this.$ekmtcCommon.getOnlyNumber(valKg, { isComma: true })

        if (!numKg.isNum) {
          this.$ekmtcCommon.showErrorTooltip(elemKg, '숫자만 입력 가능합니다.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        }

        if (numKg.num > maxKg) {
          this.$ekmtcCommon.showErrorTooltip(elemKg, '중량이 초과되었습니다.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        }
      }
      return isOk
    },
    checkValidationOceanFreight (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#oceanFreight')
      const val = elem.value
      let isOk = true

      if (val === '') {
        this.$ekmtcCommon.showErrorTooltip(elem, 'Ocean Freight 를 선택해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationScNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      // form 안에 같은 name이 2개 이상인 경우
      const elem = frm.querySelectorAll('input[name=\'tempName\']')[0]
      const val = elem.value
      let isOk = true

      if (val === '') {
        this.$ekmtcCommon.showErrorTooltip(elem, 'SC NO 를 입력해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationCheckbox (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elemsAll = frm.querySelectorAll('input[name="sampleCheckbox"]')
      const elemsChk = frm.querySelectorAll('input[name="sampleCheckbox"]:checked')
      let isOk = true

      console.log(elemsChk.length)

      if (elemsChk.length === 0) {
        this.$ekmtcCommon.showErrorTooltip(elemsAll[0], '체크박스를 선택해 주세요') // 메시지는 다국어로 처리할것 => this.$t('..key..')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemsAll[0])
      }

      return isOk
    },
    checkValidationTempList (name, idx, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elems = frm.querySelectorAll('*[name="' + name + '"]')
      let len = elems.length
      let isOk = true

      if (len <= idx) {
        return false
      }

      // 해당 필드에 따라서 Validation Check
      if (name === 'arrCol1') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Col1 값을 입력해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'arrCol2') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Col2 값을 선택해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'arrCol3') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Col3 값을 입력해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'arrCol4') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Col4 값을 입력해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'arrCol5') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Col5 값을 입력해 주세요.') // 메시지는 다국어로 처리할것 => this.$t('..key..')
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      }

      return isOk
    },
    checkValidationTempListAll (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const len = this.list.length
      const arrCols = ['arrCol1', 'arrCol2', 'arrCol3', 'arrCol4', 'arrCol5']
      const colLen = arrCols.length
      let isOk = true

      for (let i = 0; i < len; i++) {
        for (let j = 0; j < colLen; j++) {
          if (!this.checkValidationTempList(arrCols[j], i, frm)) {
            isOk = false
          }
        }
      }

      return isOk
    },
    addRows () {
      this.list.push({
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: ''
      })
    },
    removeRows (idx) {
      this.list.splice(idx, 1)

      if (this.list.length === 0) {
        this.addRows()
      }
    }
  }
}

</script>
