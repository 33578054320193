var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("form", { attrs: { id: "frm" } }, [
      _c("h2", { staticClass: "content_title" }, [_vm._v("화물정보")]),
      _c("table", { staticClass: "tbl_row" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v("실화주")]),
            _c("td", [
              _c("input", {
                staticClass: "col_6",
                attrs: { type: "text", id: "realCompany", name: "" },
                on: {
                  keyup: function ($event) {
                    return _vm.checkValidationRealCompany()
                  },
                },
              }),
              _vm._m(1),
            ]),
            _c("th", [_vm._v("G. Weight")]),
            _c("td", [
              _c("span", { staticClass: "wg" }, [
                _c("input", {
                  staticClass: "wid80",
                  attrs: { type: "text", id: "inp_weight_ton", name: "" },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationWeight()
                    },
                  },
                }),
                _vm._v(" ton "),
              ]),
              _c(
                "span",
                {
                  staticClass: "color_gray",
                  staticStyle: {
                    display: "inline-block",
                    width: "30px",
                    "text-align": "center",
                  },
                },
                [_vm._v("/")]
              ),
              _c("span", { staticClass: "wg" }, [
                _c("input", {
                  staticClass: "wid80",
                  attrs: {
                    type: "text",
                    id: "inp_weight_kg",
                    name: "",
                    value: "",
                  },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationWeight()
                    },
                  },
                }),
                _vm._v(" kg "),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("Ocean Freight")]),
            _c("td", [
              _c(
                "select",
                {
                  staticClass: "col_6",
                  attrs: { id: "oceanFreight" },
                  on: {
                    change: function ($event) {
                      return _vm.checkValidationOceanFreight()
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                  _c("option", { attrs: { value: "TEMP_A" } }, [
                    _vm._v("PREP AID"),
                  ]),
                  _c("option", { attrs: { value: "TEMP_B" } }, [
                    _vm._v("COLLECT"),
                  ]),
                ]
              ),
            ]),
            _c("th", { attrs: { scope: "row" } }, [_vm._v("SC No.")]),
            _c("td", [
              _c("input", {
                attrs: { type: "text", id: "", name: "tempName" },
                on: {
                  keyup: function ($event) {
                    return _vm.checkValidationScNo()
                  },
                },
              }),
            ]),
          ]),
          _c("tr", [
            _vm._m(2),
            _vm._m(3),
            _c("th", [_vm._v(" checkbox 선택 ")]),
            _c("td", [
              _c("span", { staticClass: "position_relative" }, [
                _c("input", {
                  attrs: {
                    type: "checkbox",
                    id: "sampleCheckbox_1",
                    name: "sampleCheckbox",
                    value: "T001",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkValidationCheckbox()
                    },
                  },
                }),
                _c("label", { attrs: { for: "sampleCheckbox_1" } }, [
                  _vm._v("T0001"),
                ]),
                _c("input", {
                  attrs: {
                    type: "checkbox",
                    id: "sampleCheckbox_2",
                    name: "sampleCheckbox",
                    value: "T002",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkValidationCheckbox()
                    },
                  },
                }),
                _c("label", { attrs: { for: "sampleCheckbox_2" } }, [
                  _vm._v("T0002"),
                ]),
                _c("input", {
                  attrs: {
                    type: "checkbox",
                    id: "sampleCheckbox_3",
                    name: "sampleCheckbox",
                    value: "T003",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkValidationCheckbox()
                    },
                  },
                }),
                _c("label", { attrs: { for: "sampleCheckbox_3" } }, [
                  _vm._v("T0003"),
                ]),
                _c("input", {
                  attrs: {
                    type: "checkbox",
                    id: "sampleCheckbox_4",
                    name: "sampleCheckbox",
                    value: "T004",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkValidationCheckbox()
                    },
                  },
                }),
                _c("label", { attrs: { for: "sampleCheckbox_4" } }, [
                  _vm._v("T0004"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("table", { staticClass: "tbl_col mt20" }, [
        _vm._m(4),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Col1")]),
            _c("th", [_vm._v("Col1")]),
            _c("th", [_vm._v("Col1")]),
            _c("th", [_vm._v("Col1")]),
            _c("th", [_vm._v("Col1")]),
            _c("th", [
              _c(
                "button",
                {
                  staticClass: "tbl_icon plus",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addRows()
                    },
                  },
                },
                [_vm._v("plus")]
              ),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.list, function (vo, idx) {
            return _c("tr", { key: "tr_" + idx }, [
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: vo.col1,
                      expression: "vo.col1",
                    },
                  ],
                  attrs: { type: "text", name: "arrCol1" },
                  domProps: { value: vo.col1 },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationTempList("arrCol1", idx)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(vo, "col1", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: vo.col2,
                        expression: "vo.col2",
                      },
                    ],
                    attrs: { name: "arrCol2" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            vo,
                            "col2",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.checkValidationTempList("arrCol2", idx)
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                    _c("option", { attrs: { value: "sel1" } }, [
                      _vm._v("SEL01"),
                    ]),
                    _c("option", { attrs: { value: "sel2" } }, [
                      _vm._v("SEL02"),
                    ]),
                    _c("option", { attrs: { value: "sel3" } }, [
                      _vm._v("SEL03"),
                    ]),
                  ]
                ),
              ]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: vo.col3,
                      expression: "vo.col3",
                    },
                  ],
                  attrs: { type: "text", name: "arrCol3" },
                  domProps: { value: vo.col3 },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationTempList("arrCol3", idx)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(vo, "col3", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: vo.col4,
                      expression: "vo.col4",
                    },
                  ],
                  attrs: { type: "text", name: "arrCol4" },
                  domProps: { value: vo.col4 },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationTempList("arrCol4", idx)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(vo, "col4", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: vo.col5,
                      expression: "vo.col5",
                    },
                  ],
                  attrs: { type: "text", name: "arrCol5" },
                  domProps: { value: vo.col5 },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationTempList("arrCol5", idx)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(vo, "col5", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "tbl_icon minus",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.removeRows(idx)
                      },
                    },
                  },
                  [_vm._v("minus")]
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "mt10 text_right" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.checkValidationAll()
              },
            },
          },
          [_vm._v("확인")]
        ),
        _c(
          "a",
          {
            staticClass: "button gray lg",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.clearValidationAll()
              },
            },
          },
          [_vm._v("Tooltip clear all")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "float_right" }, [
      _c("input", { attrs: { type: "checkbox", id: "c1", name: "cc" } }),
      _c("label", { attrs: { for: "c1" } }, [_c("span"), _vm._v("콘솔부킹")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("Bidding No."),
      _c("span", { staticClass: "txt" }, [_vm._v("(선택)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("input", { attrs: { type: "text", id: "", name: "tempName" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }